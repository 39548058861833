'use client'
import React from 'react'
import { Flex } from '@chakra-ui/react'
import { TaxInformationForm } from '../components/ui/TaxInformationForm'
import { Title } from '../components/fragments/Title'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { IconButton } from '../components/fragments/Button'
import { useNavigate } from 'react-router-dom'
import { PageLayout } from '../components/Layout/PageLayout'

const CreateTaxInformationPage = () => {
  const navigate = useNavigate()

  return (
    <PageLayout>
      <Flex
        direction='column'
        alignItems='center'
      >
        <Flex
          mt='4rem'
          mb='2rem'
          px='2rem'
          gap='1rem'
          justifyContent='center'
          alignItems='center'
        >
          <IconButton
            onClick={() => navigate(-1)}
            aria-label='go back button'
            icon={<FaArrowAltCircleLeft />}
          />
          <Title>Agrega tu Información Fiscal</Title>
        </Flex>
        <TaxInformationForm />
      </Flex>
    </PageLayout>
  )
}

export { CreateTaxInformationPage }
