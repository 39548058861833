import React, { useEffect } from 'react'
import { TextField, FileField } from '../fields'
import { useForm } from '../../hooks/useForm'
import { SelectField } from '../fields'
import * as Yup from 'yup'
import { Button } from '../fragments/Button'
import { Flex, useToast } from '@chakra-ui/react'
import { isBase64, toBase64 } from '../../utils/values'
import { colors } from '../../utils/colors'
import { useNavigate } from 'react-router-dom'
import { Flatten, ProductType } from '../../ts/interfaces/interfaces'
import { useAppSelector } from '../../store/hooks'
import { addTaxInformationToList } from '../../store/invoices/slice'
import { useDispatch } from 'react-redux'
import { InvoicesService } from '../../services/Invoices'
import { TaxInformationForm as TypeTaxInformationForm } from '../../ts/interfaces/invoices'
import { reactErrorHandler } from '@sentry/react'
import { routerObj } from '../routing/Router'

interface ITaxInformationForm {
  initialForm?: TypeTaxInformationForm | null
}

const TaxInformationScheme = Yup.object().shape({
  tax_id: Yup.string()
    .test('test number non equal to 0', '----', (value) => {
      if (!value || value.length === 0 || value.length > 13) return false
      return true
    })
    .required(),
  tax_regime: Yup.number().required(),
  business_name: Yup.string().test('test business_name not null when MORAL PERSON', 'TEST', (value, context) => {
    if (((!context.parent.tax_id || context.parent.tax_id.length === 0 || context.parent.tax_id.length < 13) && !value) || value?.length === 0) return false
    else return true
  }),
  first_name: Yup.string().test('test first_name not null when PHYSICAL PERSON', 'TEST', (value, context) => {
    if ((!context.parent.tax_id || context.parent.tax_id.length === 0 || context.parent.tax_id.length === 13) && !value) return false
    else return true
  }),
  last_name: Yup.string().test('test last_name not null when PHYSICAL PERSON', 'TEST', (value, context) => {
    if ((!context.parent.tax_id || context.parent.tax_id.length === 0 || context.parent.tax_id.length === 13) && !value) return false
    else return true
  }),
  surname: Yup.string().test('test last_name not null when PHYSICAL PERSON', 'TEST', (value, context) => {
    if ((!context.parent.tax_id || context.parent.tax_id.length === 0 || context.parent.tax_id.length === 13) && !value) return false
    else return true
  }),
  postal_code: Yup.string().required(),
  district: Yup.string().required(),
  state: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string().required(),
  neighborhood: Yup.string().required(),
  // internal_number: Yup.string().required(),
  external_number: Yup.string().required()
})

export const TAX_REGIMES = [
  { label: 'General de Ley Personas Morales', value: 601 },
  { label: 'Personas Morales con Fines no Lucrativos', value: 603 },
  { label: 'Coordinados', value: 624 },
  { label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras', value: 622 },
  { label: 'Opcional para Grupos de Sociedades', value: 623 },
  { label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas', value: 625 },
  { label: 'Régimen Simplificado de Confianza', value: 626 },
  { label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios', value: 605 },
  { label: 'Arrendamiento', value: 606 },
  { label: 'Régimen de Enajenación o Adquisición de Bienes', value: 607 },
  { label: 'Demás ingresos', value: 608 },
  { label: 'Residentes en el Extranjero sin Establecimiento Permanente en México', value: 610 },
  { label: 'Ingresos por Dividendos (socios y accionistas)', value: 611 },
  { label: 'Personas Físicas con Actividades Empresariales y Profesionales', value: 612 },
  { label: 'Ingresos por intereses', value: 614 },
  { label: 'Régimen de los ingresos por obtención de premios', value: 615 },
  { label: 'Sin obligaciones fiscales', value: 616 },
  { label: 'Incorporación Fiscal', value: 621 }
]

export const STATES = [
  { label: 'Aguascalientes', value: 'Aguascalientes' },
  { label: 'Baja California', value: 'Baja California' },
  { label: 'Baja California Sur', value: 'Baja California Sur' },
  { label: 'Campeche', value: 'Campeche' },
  { label: 'Ciudad de México', value: 'Ciudad de México' },
  { label: 'Coahuila', value: 'Coahuila' },
  { label: 'Colima', value: 'Colima' },
  { label: 'Chiapas', value: 'Chiapas' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Guanajuato', value: 'Guanajuato' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Jalisco', value: 'Jalisco' },
  { label: 'Estado de México', value: 'Estado de México' },
  { label: 'Michoacán', value: 'Michoacán' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Nayarit', value: 'Nayarit' },
  { label: 'Nuevo León', value: 'Nuevo León' },
  { label: 'Oaxaca', value: 'Oaxaca' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Querétaro', value: 'Querétaro' },
  { label: 'Quintana Roo', value: 'Quintana Roo' },
  { label: 'San Luis Potosí', value: 'San Luis Potosí' },
  { label: 'Sinaloa', value: 'Sinaloa' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Tabasco', value: 'Tabasco' },
  { label: 'Tamaulipas', value: 'Tamaulipas' },
  { label: 'Tlaxcala', value: 'Tlaxcala' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Yucatán', value: 'Yucatán' },
  { label: 'Zacatecas', value: 'Zacatecas' }
]

export const taxInformationInitialForm = {
  first_name: '',
  last_name: '',
  surname: '',
  middle_name: '',
  business_name: '',
  street: '',
  city: '',
  state: '',
  district: '',
  neighborhood: '',
  postal_code: '',
  tax_id: '',
  tax_certificate: '',
  tax_regime: null,
  internal_number: '',
  external_number: ''
}

const TaxInformationForm = React.memo(({ initialForm }: ITaxInformationForm) => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user)
  const dispatch = useDispatch()
  const subscription = useAppSelector((state) => state.subscription)

  const init = async () => {
    if (initialForm) return
    const taxInformationProduct = subscription.products.find(({ type }) => type == ProductType.tax_information)
    const taxInformationList = await InvoicesService().getTaxInformation()

    if ((taxInformationProduct && (taxInformationProduct.limit?.max as number) <= taxInformationList.length) || (!taxInformationProduct && taxInformationList.length)) {
      navigate(-1)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  const formik = useForm({
    initialValues: initialForm ? initialForm : taxInformationInitialForm,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: TaxInformationScheme,
    onSubmit: async (values) => {
      formik.handleSetTouchedForm(values)
      try {
        let response

        let restValues = {}

        if (initialForm) {
          const { tax_certificate, ...rest } = initialForm
          restValues = rest
        }

        const tax_certificate = isBase64(values.tax_certificate) ? { tax_certificate: values.tax_certificate } : {}

        const body = {
          ...restValues,
          ...tax_certificate,
          email: user.email,
          business_name: values.business_name,
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          surname: values.surname,
          tax_id: values.tax_id,
          tax_regime: values.tax_regime,
          postal_code: values.postal_code,
          address: {
            country: 'México',
            state: values.state,
            city: values.city,
            district: values.district,
            neighborhood: values.neighborhood,
            street: values.street,
            external_number: values.external_number,
            internal_number: values.internal_number
          }
        }

        if (initialForm)
          response = await InvoicesService().updateTaxInformation({
            id: initialForm.id,
            ...body
          })
        else response = await InvoicesService().createTaxInformation(body)

        dispatch(addTaxInformationToList(response.data.data))
        toast({
          title: initialForm ? 'Información fiscal actualizada exitosamente' : 'Información fiscal guardada exitosamente',
          duration: 8000,
          isClosable: true
        })
        navigate(-1)
      } catch (error) {}
    }
  })

  const toast = useToast()

  useEffect(() => {
    if (formik.values.tax_id?.length === 13) {
      formik.setFieldValue('business_name', '')
    } else {
      formik.setFieldValue('first_name', '')
      formik.setFieldValue('last_name', '')
      formik.setFieldValue('middle_name', '')
      formik.setFieldValue('surname', '')
    }
  }, [formik.values.tax_id])

  return (
    <form
      className='flex items-end justify-around flex-wrap md:max-w-[80%] gap-y-[1rem]'
      onSubmit={(e) => {
        e.preventDefault()
        formik.handleSubmit()
      }}
    >
      <TextField
        isRequired={true}
        limit={13}
        label='Registro Federal de Contribuyente (RFC)'
        name='tax_id'
        id='tax_id'
        isDisabled={false}
        value={formik.values.tax_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.tax_id ? formik.errors.tax_id : ''}
        isInvalid={formik.touched.tax_id && formik.errors.tax_id ? true : false}
      />

      <SelectField
        isRequired={true}
        id='tax_regime'
        name='tax_regime'
        label='Régimen Fiscal'
        options={TAX_REGIMES}
        value={formik.values.tax_regime}
        onChange={formik.handleSelectChange}
        onFocus={formik.handleFocus}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.tax_regime ? formik.errors.tax_regime : ''}
        isInvalid={formik.touched.tax_regime && formik.errors.tax_regime ? true : false}
      />

      {formik.values.tax_id.length === 13 ? (
        <>
          <TextField
            isRequired={true}
            label='Primer Nombre'
            name='first_name'
            id='first_name'
            isDisabled={false}
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // helperText={formik.touched.first_name ? formik.errors.first_name : ''}
            isInvalid={formik.touched.first_name && formik.errors.first_name ? true : false}
          />

          <TextField
            label='Segundo Nombre'
            name='middle_name'
            id='middle_name'
            isDisabled={false}
            value={formik.values.middle_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // helperText={formik.touched.middle_name ? formik.errors.middle_name : ''}
            isInvalid={formik.touched.middle_name && formik.errors.middle_name ? true : false}
          />

          <TextField
            label='Apellido Paterno'
            name='surname'
            id='surname'
            isRequired={true}
            isDisabled={false}
            value={formik.values.surname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // helperText={formik.touched.surname ? formik.errors.surname : ''}
            isInvalid={formik.touched.surname && formik.errors.surname ? true : false}
          />

          <TextField
            isRequired={true}
            label='Apellido Materno'
            name='last_name'
            id='last_name'
            isDisabled={false}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // helperText={formik.touched.last_name ? formik.errors.last_name : ''}
            isInvalid={formik.touched.last_name && formik.errors.last_name ? true : false}
          />
        </>
      ) : (
        <>
          <TextField
            isRequired={true}
            label='Razón Social'
            placeholder='business_name'
            name='business_name'
            id='business_name'
            isDisabled={false}
            value={formik.values.business_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // helperText={formik.touched.business_name ? formik.errors.business_name : ''}
            isInvalid={formik.touched.business_name && formik.errors.business_name ? true : false}
          />
        </>
      )}

      <TextField
        isRequired={true}
        label='Código Postal'
        placeholder='ej. 12345'
        name='postal_code'
        id='postal_code'
        isDisabled={false}
        value={formik.values.postal_code}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.postal_code ? formik.errors.postal_code : ''}
        isInvalid={formik.touched.postal_code && formik.errors.postal_code ? true : false}
      />

      <SelectField
        isRequired={true}
        id='state'
        name='state'
        label='Estado'
        options={STATES}
        value={formik.values.state}
        onChange={formik.handleSelectChange}
        onFocus={formik.handleFocus}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.tax_regime ? formik.errors.tax_regime : ''}
        isInvalid={formik.touched.state && formik.errors.state ? true : false}
      />

      <TextField
        isRequired={true}
        label='Ciudad'
        name='city'
        id='city'
        isDisabled={false}
        value={formik.values.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.district ? formik.errors.district : ''}
        isInvalid={formik.touched.city && formik.errors.city ? true : false}
      />

      <TextField
        isRequired={true}
        label='Delegación o municipio'
        name='district'
        id='district'
        isDisabled={false}
        value={formik.values.district}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.district ? formik.errors.district : ''}
        isInvalid={formik.touched.district && formik.errors.district ? true : false}
      />

      <TextField
        isRequired={true}
        label='Colonia'
        name='neighborhood'
        id='neighborhood'
        isDisabled={false}
        value={formik.values.neighborhood}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.neighborhood ? formik.errors.neighborhood : ''}
        isInvalid={formik.touched.neighborhood && formik.errors.neighborhood ? true : false}
      />

      <TextField
        isRequired={true}
        label='Calle de tu domicilio'
        name='street'
        id='street'
        isDisabled={false}
        value={formik.values.street}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.street ? formik.errors.street : ''}
        isInvalid={formik.touched.street && formik.errors.street ? true : false}
      />

      <TextField
        isRequired={true}
        label='Número exterior'
        name='external_number'
        id='external_number'
        isDisabled={false}
        value={formik.values.external_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.external_number ? formik.errors.external_number : ''}
        isInvalid={formik.touched.external_number && formik.errors.external_number ? true : false}
      />
      <TextField
        isRequired={false}
        label='Número interior'
        name='internal_number'
        id='internal_number'
        isDisabled={false}
        value={formik.values.internal_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // helperText={formik.touched.internal_number ? formik.errors.internal_number : ''}
        isInvalid={formik.touched.internal_number && formik.errors.internal_number ? true : false}
      />

      <FileField
        name='tax_certificate'
        label='Constancia de Situación Fiscal'
        accept={{ 'application/pdf': ['.pdf'] }}
        formValue={formik.values.tax_certificate}
        onChange={async (name, value) => {
          try {
            const base64File = await toBase64(value[0])
            formik.setFieldValue(name, base64File)
          } catch (error) {
            toast({
              title: 'Hubo un problema al seleccionar el archivo.',
              status: 'error',
              duration: 8000,
              isClosable: true
            })
          }
        }}
      />

      <Flex
        w='100%'
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        mt={4}
        gap='1rem'
        mb={initialForm ? '' : '3rem'}
      >
        <Button
          isLoading={formik.isSubmitting}
          type='submit'
        >
          {initialForm ? 'Actualizar Información' : 'Registrar Información'}
        </Button>
        <Button
          isLoading={formik.isSubmitting}
          borderStyle='dashed'
          bg='transparent'
          borderColor={colors.text[4]}
          textColor={colors.text[2]}
          _hover={{
            borderColor: colors.text[1]
          }}
          _active={{
            bg: 'transparent'
          }}
          onClick={() => navigate(-1)}
        >
          {'<<'} Cancelar
        </Button>
      </Flex>
    </form>
  )
})

export { TaxInformationForm }
