export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.onerror = reject
  })

export async function downloadFile(url: string, fileName: string) {
  fetch(url, {
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.blob() // Convert the response to a blob
    })
    .then((blob) => {
      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob) // Create a URL for the blob
      link.download = fileName // Set the file name for the download
      document.body.appendChild(link) // Append the link to the body
      link.click() // Programmatically click the link to trigger the download
      document.body.removeChild(link) // Remove the link from the document
      window.URL.revokeObjectURL(link.href) // Release the memory used by the URL object
    })
    .catch((error) => {
      console.error('Error downloading file:', error)
    })
}

export function isBase64(str?: string | null): boolean {
  if (!str) return false
  // Basic regex to check if the string could be Base64
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/

  // Check if the string matches the regex
  if (!base64Regex.test(str)) {
    return false
  }

  try {
    // Attempt to decode the string and check if it results in a valid string
    const decoded = atob(str)
    return btoa(decoded) === str
  } catch (err) {
    // If decoding fails, it's not a valid Base64
    return false
  }
}
