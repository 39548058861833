import * as React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Flex } from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { useReactTable, flexRender, getCoreRowModel, ColumnDef, SortingState, getSortedRowModel, PaginationState, getPaginationRowModel } from '@tanstack/react-table'
import { colors } from '../../utils/colors'
import { Paginator } from './Paginator'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

export type IDataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
}

export const PAGINATION_CONFIG = {
  initialConfig: {
    pageIndex: 0, //initial page index
    pageSize: 5 //default page size
  },
  pages: [5, 10, 25, 50]
}
export function DataTable<Data extends object>({ data, columns }: IDataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [pagination, setPagination] = React.useState<PaginationState>(PAGINATION_CONFIG.initialConfig)
  const pageCount = Math.ceil(data.length / pagination.pageSize)
  const table = useReactTable({
    columns,
    data: data,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    paginateExpandedRows: false,
    state: {
      sorting,
      pagination: pagination
    }
  })

  const boundingBoxRef = React.useRef(null)

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      minHeight={{
        base: '',
        lg: '550px'
      }}
      overflowX='auto'
      maxW='100%'
    >
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta: any = header.column.columnDef.meta
                return (
                  <Th
                    fontSize={{
                      base: '.7rem'
                    }}
                    _hover={{
                      backgroundColor: colors.neutro[1]
                    }}
                    cursor='pointer'
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span pl='4'>{header.column.getIsSorted() ? header.column.getIsSorted() === 'desc' ? <TriangleDownIcon aria-label='sorted descending' /> : <TriangleUpIcon aria-label='sorted ascending' /> : null}</chakra.span>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody
          alignContent='start'
          placeContent='start'
          justifyContent='start'
        >
          {table.getRowModel().rows.map((row) => (
            <Tr
              maxW='100vw'
              ref={boundingBoxRef}
              transition='.2s ease-in'
              _hover={{
                backgroundColor: colors.neutro[1]
              }}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => {
                const meta: any = cell.column.columnDef.meta
                return (
                  <Td
                    // maxW={window.innerWidth / row.getVisibleCells().length + 1}
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    fontSize={{
                      base: '1rem'
                    }}
                    overflow='hidden'
                    flexWrap='wrap'
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Paginator
        pageCount={pageCount}
        pagination={pagination}
        table={table}
      />
    </Flex>
  )
}
