'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { AbsoluteCenter, useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm } from '../hooks/useForm'
import { TextField } from '../components/fields'
import { padding, rounded } from '../utils/ui'
import { Title } from '../components/fragments/Title'
import { Button } from '../components/fragments/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../store/hooks'
import { routerObj } from '../components/routing/Router'
import { AuthService } from '../services/Auth'
import { refreshToken } from '../store/user/slice'
import { useDispatch } from 'react-redux'

const RenewPasswordSchema = Yup.object().shape({
  password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Passwords must match')
})

const RenewPasswordPage = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user)
  const location = useLocation()
  const dispatch = useDispatch()
  const toast = useToast()

  React.useEffect(() => {
    if (!location.hash) {
      if (!user.token) navigate(routerObj.signIn.path)
      else navigate(-1)
    }
  }, [location, user])

  const data = React.useMemo(() => {
    const formatted = location.hash.replace('#', '&').split('&')
    const access_token = (formatted.find((val) => val.match('access_token')) as string)?.split('=')[1]
    const refresh_token = (formatted.find((val) => val.match('refresh_token')) as string)?.split('=')[1]
    return {
      accessToken: access_token || '',
      refreshToken: refresh_token || ''
    }
  }, [location])

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: RenewPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await AuthService().resetPassword({
          password: values.password.trim(),
          access_token: data.accessToken,
          refresh_token: data.refreshToken
        })

        toast({
          title: 'Contraseña actualizada exitosamente.',
          duration: 8000,
          isClosable: true
        })

        if (user.token && response) {
          dispatch(refreshToken({ refresh_token: response.session.refresh_token, token: response.session.access_token }))
          navigate(routerObj.home.path)
        } else {
          navigate(routerObj.signIn.path)
        }
      } catch (error) {
        console.log({ error })
        toast({
          title: 'Hubo un problema.',
          status: 'error',
          duration: 8000,
          isClosable: true
        })
      }
    }
  })

  return (
    <PageLayout>
      <AbsoluteCenter
        width={{
          base: 'full',
          md: 'max-content'
        }}
        axis='both'
        bg='white'
        py={padding.container.py}
        px={padding.container.px}
        rounded={rounded.container}
      >
        <form
          className='flex flex-col items-center gap-[.9rem] pb-[1rem]'
          onSubmit={(e) => {
            e.preventDefault()
            form.handleSubmit()
          }}
        >
          <Title>Cambiar Contraseña</Title>
          <TextField
            isReadOnly={form.isSubmitting}
            isRequired={true}
            label='Contraseña'
            name='password'
            id='password'
            type='PASSWORD'
            isDisabled={false}
            value={form.values.password}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={form.touched.password && form.errors.password ? true : false}
          />

          <TextField
            isReadOnly={form.isSubmitting}
            isRequired={true}
            label='Introduzca de nuevo su contraseña'
            name='confirmPassword'
            id='confirmPassword'
            type='PASSWORD'
            isDisabled={false}
            value={form.values.confirmPassword}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={form.touched.confirmPassword && form.errors.confirmPassword ? true : false}
          />

          <Button
            isLoading={form.isSubmitting}
            mt={4}
            type='submit'
          >
            Cambiar Contraseña
          </Button>
        </form>
      </AbsoluteCenter>
    </PageLayout>
  )
}

export { RenewPasswordPage }
