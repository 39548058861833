'use client'
import React from 'react'
import { createColumnHelper, Row, Column } from '@tanstack/react-table'
import { DataTable } from '../components/fragments/Table'
import { useNavigate } from 'react-router-dom'
import { routerObj } from '../components/routing/Router'
import { Flex, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import { FaPencilAlt, FaPlus } from 'react-icons/fa'
import { PageLayout } from '../components/Layout/PageLayout'
import { Title } from '../components/fragments/Title'
import { Button } from '../components/fragments/Button'
import { useAppSelector } from '../store/hooks'
import { TaxInformation } from '../ts/interfaces/invoices'
import { TAX_REGIMES } from '../components/ui/TaxInformationForm'
import { InvoicesService } from '../services/Invoices'
import { Buffer } from 'buffer'
import { ProductType } from '../ts/interfaces/interfaces'
import { ConfirmDialog } from '../components/fragments/ConfirmDialog'

interface TableData extends Omit<TaxInformation, 'business_name' | 'first_name' | 'last_name' | 'surname' | 'middle_name'> {
  identification: string
  action?: string
}
const TaxInformationDashboardPage = () => {
  const navigate = useNavigate()
  const surpassedLimitModal = useDisclosure()
  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const columnHelper = createColumnHelper<TableData>()
  const subscription = useAppSelector((state) => state.subscription)

  const taxInformationLimit: number = React.useMemo(() => {
    const taxInformationProduct = subscription.products.find(({ type }) => type == ProductType.tax_information)

    return taxInformationProduct ? (taxInformationProduct.limit?.max as number) : 1
  }, [subscription])

  React.useEffect(() => {
    InvoicesService().getTaxInformation()
  }, [])

  const tableData = React.useMemo(
    () =>
      taxInformationList.length
        ? taxInformationList.reduce((acc, item) => {
            const { business_name, first_name, surname, last_name, middle_name } = item

            const identification = item.tax_id.length === 13 ? `${first_name} ${surname}` : business_name

            acc.push({
              identification,
              ...item
            })

            return acc
          }, [] as TableData[])
        : [],
    [taxInformationList]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('tax_id', {
        cell: (info) => info.getValue(),
        header: 'Registro Federal de Contribuyente (RFC)'
      }),
      columnHelper.accessor('identification', {
        cell: (info) => info.getValue(),
        header: 'Razón Social'
      }),
      columnHelper.accessor('postal_code', {
        cell: (info) => info.getValue(),
        header: 'Código Postal'
      }),
      columnHelper.accessor('tax_regime', {
        cell: (info) => TAX_REGIMES.find(({ value }) => value === info.getValue())?.label,
        header: 'Regimen fiscal'
      }),
      columnHelper.accessor('action', {
        cell: (data) => {
          return (
            <IconButton
              onClick={() => {
                navigate(`${routerObj.updateTaxInformation.cleanParamsPath}/${Buffer.from(data.row.original.id.toString()).toString('base64')}`)
              }}
              aria-label='row menu'
              icon={<FaPencilAlt />}
            />
          )
        },
        header: 'Acciones'
      })
    ],
    [navigate, taxInformationList]
  )

  const handleAddTaxInformation = React.useCallback(() => {
    const taxInformationProduct = subscription.products.find(({ type }) => type == ProductType.tax_information)

    if ((taxInformationProduct && (taxInformationProduct.limit?.max as number) <= taxInformationList.length) || (!taxInformationProduct && taxInformationList.length)) {
      surpassedLimitModal.onOpen()
      return
    }

    navigate(routerObj.createTaxInformation.cleanParamsPath)
  }, [navigate, routerObj, subscription])

  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row'
        }}
        mb='1rem'
        alignItems='center'
        justifyContent={{
          base: '',
          md: 'space-between'
        }}
      >
        <Title>Razones Sociales</Title>
        <Button
          width='fit-content'
          onClick={handleAddTaxInformation}
          gap='0.4rem'
        >
          Agregar Razón Social{' '}
          <Icon
            aria-label='agregar grupo'
            as={FaPlus}
          />
        </Button>
      </Flex>

      <DataTable
        data={tableData}
        columns={columns}
      />

      <ConfirmDialog
        isOpen={surpassedLimitModal.isOpen}
        onClose={surpassedLimitModal.onClose}
        title={`Tu plan actual solo permite ${taxInformationLimit > 1 ? `${taxInformationLimit} razones sociales` : '1 razón social'}.  Si quieres contratar una Razón Social adicional por favor contáctanos.`}
        confirmMethod={() => {
          window.open(process.env.REACT_APP_HELP_URL, '_blank')
        }}
        cancelType='red'
        confirmType='blue'
        confirmMessage='Contactar'
        cancelMessage='Cancelar'
      />
    </PageLayout>
  )
}

export { TaxInformationDashboardPage }
