import { Box, Flex, Link, List, ListIcon, ListItem, Text, Icon, Grid, Alert, AlertIcon, AlertTitle, Heading } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded, formatCurrencyAmount } from '../../utils/ui'
import { MdCheckCircle } from 'react-icons/md'
import { FaCaretSquareRight } from 'react-icons/fa'

interface IPricingBannerProps {
  isNewUser?: boolean
}

interface Product {
  name: string
  amount: number | string | null
  url?: string
  benefits?: string[]
  callToAction?: string
}

const PricingBanner = React.memo(({ isNewUser }: IPricingBannerProps) => {
  const mockProducts: Omit<Product, 'type'>[] = [
    {
      name: 'Plan Básico',
      amount: 300,
      benefits: ['Factura hasta 25 tickets de gastos en automático desde WhatsApp.', '1 Usuario de WhatsApp.', '1 Razón Social.', 'Panel de control donde podrás ver y descargar todas las facturas generadas a tu empresa.'],
      url: process.env.REACT_APP_BASIC_PLAN_STRIPE_URL
    },
    {
      name: 'Plan Emprendedor',
      amount: 500,
      benefits: ['Factura hasta 50 tickets de gastos en automático desde WhatsApp.', '1 Usuario de WhatsApp.', '1 Razón Social.', 'Panel de control donde podrás ver y descargar todas las facturas generadas a tu empresa.'],
      url: process.env.REACT_APP_ENTREPRENEUR_PLAN_STRIPE_URL
    },
    {
      name: 'Plan Empresa',
      amount: 1025,
      benefits: ['Factura hasta 100 tickets de gastos en automático desde WhatsApp.', 'Hasta 3 usuarios de WhatsApp.', 'Hasta 2 razones sociales.', 'Panel de control donde podrás ver y descargar todas las facturas generadas a tu empresa.'],
      url: process.env.REACT_APP_ENTERPRISE_PLAN_STRIPE_URL
    },
    {
      name: 'Plan Enterprise',
      amount: '',
      url: process.env.REACT_APP_HELP_URL,
      benefits: ['Personaliza el número tickets a facturar que se generaran en automático desde WhatsApp.', 'Personaliza el número de usuarios que tienen acceso a WhatsApp.', 'Personaliza el número de razones sociales.', 'Panel de control donde podrás ver y descargar todas las facturas generadas a tu empresa.'],
      callToAction: 'Contáctanos'
    }
  ]

  const Product = ({ name, amount, benefits = [], url, callToAction }: Omit<Product, 'type'>) => (
    <Flex
      minW={{
        base: 150,
        md: 195
      }}
      minH={{
        base: 320,
        md: '100%'
      }}
      flexDirection='column'
      gap='0.7rem'
      bg={colors.white}
      borderWidth='2px'
      rounded={rounded.container}
      borderColor={colors.tertiary[1]}
      boxShadow='md'
      _hover={{
        boxShadow: 'xl'
      }}
      transition='.5s ease'
      px={{
        base: '1rem',
        sm: '0.5rem'
      }}
      pt={{
        base: '1rem'
      }}
      pb={{
        base: '1rem',
        sm: '1.8rem',
        md: '2.2rem'
      }}
    >
      <Grid
        // flexDir='column'
        // alignItems='center'
        // justifyContent='space-between'
        templateRows={{
          base: 'repeat(3, 1fr)'
        }}
        placeItems='center'
        px='0.5rem'
        pt={{
          md: '2.2rem'
        }}
        pb={{
          base: '1rem',
          md: '1.5rem'
        }}
        // minH='180px'
        // maxH='fit-content'
        // height='fit-content'
      >
        <Text
          textAlign='center'
          fontSize='1.2rem'
          fontWeight={700}
        >
          {name}
        </Text>
        <Flex
          height='fit-content'
          textAlign='center'
          alignItems='center'
        >
          <Text
            fontSize={typeof amount === 'number' ? '1.4rem' : '.7rem'}
            fontWeight={600}
          >
            {typeof amount === 'number' ? formatCurrencyAmount(amount) : amount}
          </Text>
          {typeof amount === 'number' ? <Text fontSize='0.8rem'>/Mes</Text> : null}
        </Flex>

        <Link
          bg={colors.primary[3]}
          p='.4rem'
          rounded={rounded.input}
          fontSize='1rem'
          fontWeight={600}
          textColor={colors.primary[4]}
          transition='.25s ease'
          _hover={{
            boxShadow: 'lg'
          }}
          href={url}
        >
          {callToAction ? callToAction : isNewUser ? 'Registrarme' : 'Suscribirte'}
        </Link>
      </Grid>

      <List
        alignItems='center'
        flexDir='column'
        width='100%'
        spacing={3}
        px='0.5rem'
      >
        {benefits.map((benefit, index) => (
          <ListItem
            flexDir='row'
            alignItems='center'
            fontSize='0.85rem'
            key={`${benefit}-${index}`}
          >
            <ListIcon
              as={MdCheckCircle}
              color='green.500'
            />
            {benefit}
          </ListItem>
        ))}
      </List>
    </Flex>
  )

  return (
    <Box>
      <Flex
        mb='2rem'
        flexDir='column'
      >
        <Heading
          as='h1'
          fontSize={{
            base: '1.8rem',
            md: '2.5rem'
          }}
          fontWeight={600}
          mb='0.5rem'
        >
          Planes y Precios
        </Heading>
        <Text>Encuentra el plan perfecto para ti y tu negocio. Ofrecemos diversas opciones para satisfacer tus necesidades, desde pequeñas empresas hasta grandes corporaciones.</Text>
      </Flex>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(4, 1fr)'
        }}
        w='full'
        justifyContent={{
          base: 'center',
          md: 'space-around'
        }}
        flexWrap='wrap'
        rowGap={{
          base: 4,
          md: 2
        }}
        columnGap={{
          sm: 4,
          md: 2,
          lg: 4,
          xl: 4
        }}
      >
        {mockProducts.map((product, index) => (
          <Product
            {...product}
            key={`${product.name}-${index}`}
          />
        ))}
      </Grid>

      <Alert
        mx='auto'
        borderRadius={rounded.container}
        p='0.7rem'
        bg={colors.tertiary[3]}
        mt='2rem'
        w='fit-content'
        status='info'
      >
        <AlertIcon />
        <AlertTitle> Todos nuestros precios incluyen IVA.</AlertTitle>
      </Alert>

      <Box mt='2rem'>
        <Link
          href={process.env.REACT_APP_HELP_URL}
          target='_blank'
          fontWeight={500}
        >
          ¿No encuentras un plan que te funcione? Contáctanos para hacer un plan personalizado a tu medida. <Icon as={FaCaretSquareRight} />
        </Link>
      </Box>
    </Box>
  )
})

export { PricingBanner }
