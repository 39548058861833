import React from 'react'
import { ButtonProps, Button as ChakraUiButton, IconButton as ChakraUiIconButton, IconButtonProps } from '@chakra-ui/react'
import { colors } from '../../utils/colors'

const Button = React.memo((props: ButtonProps) => {
  return (
    <ChakraUiButton
      width={{
        base: 300
      }}
      color={colors.tertiary[1]}
      px={{
        base: 16
      }}
      py={{
        base: 2
      }}
      // bg={colors.tertiary[3]}
      border={1.5}
      textColor={colors.tertiary[1]}
      borderColor={{
        base: colors.tertiary[1]
      }}
      _hover={{
        // bg: colors.tertiary[3],
        textColor: colors.tertiary[1]
      }}
      _active={{
        bg: 'transparent'
      }}
      fontWeight={600}
      rounded={'4px'}
      className={'font-inter active:scale-105 transition-transform active:ring-[0.4] '}
      {...props}
    />
  )
})

const IconButton = React.memo((props: IconButtonProps) => {
  return (
    <ChakraUiIconButton
      maxWidth={{
        sm: 'fit-content'
      }}
      color={colors.tertiary[1]}
      py={{
        base: 2
      }}
      bg={colors.tertiary[3]}
      border={1.5}
      textColor={colors.tertiary[1]}
      borderColor={{
        base: colors.tertiary[1]
      }}
      _hover={{
        // bg: colors.tertiary[3],
        textColor: colors.tertiary[1]
      }}
      _active={{
        bg: 'transparent'
      }}
      className={'font-inter font-semibold rounded-[12px] active:scale-105 transition-transform active:ring-[0.4] '}
      {...props}
    />
  )
})

export { Button, IconButton }
