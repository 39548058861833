export interface Currency {
  isoCode: string
  name: string
  symbol: string
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE'
}
export interface User {
  id: number
  email: string
  name?: string
  phone_numbers: string[]
  created_at?: Date
  updated_at?: Date
  role: UserRole
}

export interface UserForm extends Omit<User, 'id' | 'created_at' | 'updated_at' | 'phone_numbers' | 'role'> {
  phone_number: string
}

export interface UpdateUserForm extends Omit<User, 'created_at' | 'updated_at' | 'phone_numbers' | 'role'> {
  phone_number: string
}

export interface AuthUser extends User {
  token: string
  refresh_token: string
}

type Primitive = string | number | boolean | null
type FlattenPairs<T> = { [K in keyof T]: T[K] extends Primitive ? [K, T[K]] : FlattenPairs<T[K]> }[keyof T] & [PropertyKey, Primitive]
export type Flatten<T> = { [P in FlattenPairs<T> as P[0]]: P[1] }

export enum ProductType {
  product = 'product',
  invoices = 'invoices',
  seat = 'seat',
  tax_information = 'tax_information'
}

export const TranslatedProductType: Record<string, string> = {
  invoices: 'Límite de facturas',
  seat: 'Número de usuarios',
  tax_information: 'Número de razones sociales'
}

export interface Limit {
  max: number
}
export interface Product {
  id: number
  name: string
  stripe_price_id?: string
  type: ProductType
  limit?: Limit

  created_at?: Date
  updated_at?: Date
}

export enum GroupRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE'
}

export interface Group {
  id: number
  name: string
  role: GroupRole
}
