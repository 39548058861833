import { Box, BoxProps, Icon, Link, List, ListItem, Progress, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded } from '../../utils/ui'
import { FaCheckCircle, FaCircle } from 'react-icons/fa'
import { routerObj } from '../routing/Router'
import { useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { InvoicesService } from '../../services/Invoices'
import { Buffer } from 'buffer'

interface IOnboardingProgressProps extends BoxProps {}

const OnboardingProgress = React.memo(({ ...props }: IOnboardingProgressProps) => {
  const invoices = useAppSelector((state) => state.invoices)
  const subscription = useAppSelector((state) => state.subscription)
  const user = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  const onboardingProgressValue: number = React.useMemo(() => {
    const hasTaxInformation = invoices.taxInformation.length ? 33.33 : 0
    const hasSubscription = subscription.products.length ? 33.33 : 0
    const sentFirstInvoice = invoices.count ? 33.33 : 0
    return hasTaxInformation + hasSubscription + sentFirstInvoice
  }, [invoices, subscription])

  const sendFirstInvoiceUrl = React.useMemo(() => `whatsapp://send?phone=${process.env.REACT_APP_ZUMMI_WHATSAPP_PHONE_NUMBER}&text=${encodeURIComponent(`¡Hola Zummi! Quiero facturar un ticket 🧾`)}`, [user])

  React.useEffect(() => {
    InvoicesService().getTaxInformation()
    InvoicesService().getInvoicesStatus()
  }, [])

  if (onboardingProgressValue > 99) return null

  return (
    <Box
      borderStyle='dashed'
      bg='transparent'
      borderColor={colors.tertiary[2]}
      borderWidth={2}
      rounded={rounded.container}
      padding='1rem'
      {...props}
    >
      <Text
        as='h3'
        fontSize='1.5rem'
        fontWeight={600}
      >
        Completa tu usuario y empieza a usar el servicio
      </Text>

      <Progress
        colorScheme='purple'
        rounded='0.15rem'
        size='md'
        value={onboardingProgressValue}
        my='1rem'
        hasStripe
      />
      <List spacing='.3rem'>
        <ListItem>
          <Link
            textColor={invoices.taxInformation.length ? colors.text[3] : 'initial'}
            fontSize='1.3rem'
            href={routerObj.createTaxInformation.path}
            onClick={(e) => {
              e.preventDefault()

              if (invoices.taxInformation.length > 0) return navigate(`${routerObj.updateTaxInformation.cleanParamsPath}/${Buffer.from(invoices.taxInformation[0].id.toString()).toString('base64')}`)

              navigate(routerObj.createTaxInformation.path)
            }}
          >
            <Icon
              color={colors.tertiary[1]}
              as={invoices.taxInformation.length ? FaCheckCircle : FaCircle}
            />{' '}
            Completa tu información fiscal&nbsp;
            <b>aquí.</b>
          </Link>
        </ListItem>
        <ListItem>
          <Link
            textColor={subscription.products.length ? colors.text[3] : 'initial'}
            fontSize='1.3rem'
            href={routerObj.pricing.path}
            onClick={(e) => {
              e.preventDefault()

              if (subscription.products.length > 0) return

              navigate(routerObj.pricing.path)
            }}
          >
            <Icon
              color={colors.tertiary[1]}
              as={subscription.products.length ? FaCheckCircle : FaCircle}
            />{' '}
            Paga tu suscripción&nbsp;
            <b>aquí.</b>
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href={sendFirstInvoiceUrl}
            textColor={invoices.count ? colors.text[3] : 'initial'}
            fontSize='1.3rem'
          >
            <Icon
              color={colors.tertiary[1]}
              as={invoices.count ? FaCheckCircle : FaCircle}
            />{' '}
            Manda tu primer ticket a facturar&nbsp;
            <b>aquí.</b>
          </Link>
        </ListItem>
      </List>
    </Box>
  )
})

export { OnboardingProgress }
