'use client'
import React from 'react'
import { OnboardingProgress } from '../components/ui/OnboardingProgress'
import { ServiceUseProgress } from '../components/ui/ServiceUseProgress'
import { SendInvoiceBanner } from '../components/ui/SendInvoiceBanner'
import { PageLayout } from '../components/Layout/PageLayout'
import { InvoicesService } from '../services/Invoices'

const HomePage = () => {
  return (
    <PageLayout>
      <ServiceUseProgress mb='1rem' />
      <OnboardingProgress mb='1.5rem' />
      <SendInvoiceBanner />
    </PageLayout>
  )
}

export { HomePage }
