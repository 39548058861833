'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { AbsoluteCenter, useToast, Flex } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm } from '../hooks/useForm'
import { TextField } from '../components/fields'
import { padding, rounded } from '../utils/ui'
import { Title } from '../components/fragments/Title'
import { Button } from '../components/fragments/Button'
import { useNavigate } from 'react-router-dom'
import { routerObj } from '../components/routing/Router'
import { AuthService } from '../services/Auth'
import ZummaBannerLogo from '../assets/svg/ZummaBannerLogo.svg'
import { ReactSVG } from 'react-svg'
import { signIn } from '../store/user/slice'
import { colors } from '../utils/colors'

const RecoverEmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
})

const RecoverPasswordPage = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const form = useForm({
    initialValues: {
      email: ''
    },
    validationSchema: RecoverEmailSchema,
    onSubmit: async (values) => {
      try {
        await AuthService().sendResetPasswordEmail(values.email.trim())
        toast({
          title: 'Email de cambio de contraseña enviado.',
          status: 'info',
          duration: 8000,
          isClosable: true
        })
        navigate(routerObj.signIn.path)
      } catch (error) {
        toast({
          title: 'Hubo un problema.',
          status: 'error',
          duration: 8000,
          isClosable: true
        })
      }
    }
  })

  return (
    <PageLayout>
      <AbsoluteCenter
        width={{
          base: 'full',
          md: 'max-content'
        }}
        axis='both'
        bg='white'
        py={padding.container.py}
        px={padding.container.px}
        rounded={rounded.container}
      >
        <Flex
          justifyContent='center'
          mb='1rem'
        >
          <ReactSVG src={ZummaBannerLogo} />
        </Flex>
        <form
          className='flex flex-col items-center gap-[.9rem] pb-[1rem]'
          onSubmit={(e) => {
            e.preventDefault()
            form.handleSubmit()
          }}
        >
          <Title>Cambiar Contraseña</Title>
          <TextField
            isReadOnly={form.isSubmitting}
            isRequired={true}
            label='Correo electrónico'
            name='email'
            id='email'
            isDisabled={false}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={form.touched.email && form.errors.email ? true : false}
          />
          <Button
            isLoading={form.isSubmitting}
            mt={4}
            type='submit'
          >
            Enviar correo de recuperación
          </Button>{' '}
          <Button
            isLoading={form.isSubmitting}
            borderStyle='dashed'
            bg='transparent'
            borderColor={colors.text[4]}
            textColor={colors.text[2]}
            _hover={{
              borderColor: colors.text[1]
            }}
            _active={{
              bg: 'transparent'
            }}
            onClick={() => navigate(routerObj.signIn.path)}
          >
            {'<<'} Iniciar sesión
          </Button>
        </form>
      </AbsoluteCenter>
    </PageLayout>
  )
}

export { RecoverPasswordPage }
