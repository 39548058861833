'use client'
import axiosLib, { AxiosError, AxiosInstance, CreateAxiosDefaults } from 'axios'
import { uiSlice } from '../store/ui/slice'
import { store } from '../store'
import { userSlice } from '../store/user/slice'
import { router, routerObj } from '../components/routing/Router'

export interface IRequestHandler extends CreateAxiosDefaults {
  useLoader?: boolean
}
export const RequestHandler = ({ headers = {}, useLoader = true, ...config }: IRequestHandler): AxiosInstance => {
  const user = store.getState().user
  const BASE_URL = process.env.REACT_APP_ZUMMA_SERVICE_SERVICE_URL + '/v1'

  const requestHandler = axiosLib.create({
    headers: {
      Authorization: `Bearer ${user.token}`,
      ...headers
    },
    baseURL: BASE_URL,
    ...config
  })

  requestHandler.interceptors.request.use(
    async function (config) {
      if (useLoader) store.dispatch(uiSlice.actions.setIsFetching(true))
      return config
    },
    function (error: AxiosError) {
      if (useLoader) store.dispatch(uiSlice.actions.setIsFetching(false))
      const status = error.response?.status || error.status || 0
      if ([403, 401].includes(status)) {
        store.dispatch(userSlice.actions.logOut())
        router.navigate(routerObj.signIn.path)
        return null
      }
      store.dispatch(uiSlice.actions.setError({ errorMessage: error.message, errorStatus: error.status }))
      return Promise.reject(error)
    }
  )

  requestHandler.interceptors.response.use(
    async function (config) {
      if (useLoader) store.dispatch(uiSlice.actions.setIsFetching(false))

      return config
    },
    function (error: AxiosError) {
      if (useLoader) store.dispatch(uiSlice.actions.setIsFetching(false))
      const status = error.response?.status || error.status || 0
      if ([403, 401].includes(status)) {
        store.dispatch(userSlice.actions.logOut())
        router.navigate(routerObj.signIn.path)
        return null
      }
      // Handle the error
      store.dispatch(uiSlice.actions.setError({ errorMessage: error.message, errorStatus: error.status }))
      return Promise.reject(error)
    }
  )

  return requestHandler
}
