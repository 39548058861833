import { Badge, Box, BoxProps, Flex, Progress, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded, padding, fontSize } from '../../utils/ui'
import { useAppSelector } from '../../store/hooks'
import { useEffect } from 'react'
import { InvoicesService } from '../../services/Invoices'
import { ProductType } from '../../ts/interfaces/interfaces'

interface IServiceUseProgressProps extends BoxProps {}

const ServiceUseProgress = React.memo(({ ...props }: IServiceUseProgressProps) => {
  const invoices = useAppSelector((state) => state.invoices)
  const subscription = useAppSelector((state) => state.subscription)

  const doesApply = React.useMemo(() => subscription.products.find(({ type }) => type === ProductType.invoices), [subscription])

  const init = async () => {
    if (doesApply) await InvoicesService().getInvoicesStatus()
  }

  useEffect(() => {
    init()
  }, [])

  if (!doesApply) return <></>

  return (
    <Box
      bg={colors.white}
      borderColor={colors.border}
      borderWidth={1}
      rounded={rounded.container}
      padding='1rem'
      {...props}
    >
      <Flex
        w='100%'
        flexWrap='wrap'
        flexDir={{
          base: 'row',
          lg: 'row'
        }}
        justifyContent={{
          base: 'space-between',
          lg: 'space-between'
        }}
        alignItems={{
          base: 'start',
          lg: 'center'
        }}
      >
        <Flex
          flexDir='column'
          alignItems='start'
        >
          <Badge
            fontSize={{
              base: '1.6rem',
              md: "'1.6rem'"
            }}
            fontWeight={600}
            colorScheme='purple'
          >
            {invoices.count}
          </Badge>
          <Text
            as='span'
            fontWeight={500}
            fontSize={fontSize.title}
          >
            Tickets enviados
          </Text>
        </Flex>
        <Flex
          flexDir='column'
          alignItems='end'
        >
          <Badge
            fontSize={{
              base: '1.6rem',
              md: "'1.6rem'"
            }}
            fontWeight={600}
            colorScheme='purple'
          >
            {invoices.limit}
          </Badge>
          <Text
            as='span'
            fontWeight={500}
            fontSize={fontSize.title}
          >
            Límite de tickets
          </Text>
        </Flex>
      </Flex>
      <Progress
        colorScheme='blue'
        rounded='0.15rem'
        hasStripe
        size='md'
        value={(!invoices.count ? 0 : invoices.count * 100) / invoices.limit}
        my='1rem'
      />
    </Box>
  )
})

export { ServiceUseProgress }
