import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Layout } from '../Layout/Layout'
import { HomePage } from '../../pages/HomePage'
import { Status404 } from '../../pages/404'
import { SignUp } from '../../pages/SignUp'
import { SignIn } from '../../pages/SignIn'
import { PricingPage } from '../../pages/PricingPage'
import { TaxInformationDashboardPage } from '../../pages/TaxInformationDashboardPage'
import { UsersDashboardPage } from '../../pages/UsersDashboardPage'
import { ProfilePage } from '../../pages/ProfilePage'
import { UpdateTaxInformationPage } from '../../pages/UpdateTaxInformationFormPage'
import { GroupsDashboardPage } from '../../pages/GroupsDashboardPage'
import { CreateTaxInformationPage } from '../../pages/CreateTaxInformationPage'
import { RenewPasswordPage } from '../../pages/RenewPasswordPage'
import { RecoverPasswordPage } from '../../pages/RecoverPasswordPage'
import { ReceiptsDashboardPage } from '../../pages/ReceiptsDashboard'
import { ReceiptDetails } from '../../pages/ReceiptDetails'
import { AddUserPage } from '../../pages/AddUserPage'
import { WelcomeSubscriptionPage } from '../../pages/WelcomeSubscriptionPage'
import { UpdateUserPage } from '../../pages/UpdateUserPage'

export const routerObj = {
  signUp: {
    path: '/sign-up',
    element: <SignUp />
  },
  signIn: {
    path: '/sign-in',
    element: <SignIn />
  },
  home: {
    path: '/dashboard',
    element: <HomePage />
  },
  pricing: {
    path: '/pricing',
    element: <PricingPage />
  },
  createTaxInformation: {
    path: '/tax-information/create',
    cleanParamsPath: '/tax-information/create',
    element: <CreateTaxInformationPage />
  },
  updateTaxInformation: {
    path: '/tax-information/update/:id',
    cleanParamsPath: '/tax-information/update',
    element: <UpdateTaxInformationPage />
  },
  taxInformationDashboard: {
    path: '/tax-information/dashboard',
    element: <TaxInformationDashboardPage />
  },
  usersDashboard: {
    path: '/user/dashboard',
    element: <UsersDashboardPage />
  },
  AddUser: {
    path: '/user/create',
    element: <AddUserPage />
  },
  UpdateUser: {
    path: '/user/update/:id',
    cleanParamsPath: '/user/update',
    element: <UpdateUserPage />
  },
  receiptsDashboard: {
    path: '/receipt/dashboard',
    element: <ReceiptsDashboardPage />
  },
  groupsDashboard: {
    path: '/group/dashboard',
    element: <GroupsDashboardPage />
  },
  profile: {
    path: '/user/profile',
    element: <ProfilePage />
  },
  renewPassword: {
    path: '/renew-password/',
    cleanParamsPath: '/renew-password',
    element: <RenewPasswordPage />
  },
  recoverPassword: {
    path: '/recover-password/',
    element: <RecoverPasswordPage />
  },
  receiptDetails: {
    path: '/receipt/:id',
    cleanParamsPath: '/receipt',
    element: <ReceiptDetails />
  },
  welcomeSubscriptionPage: {
    path: '/welcome',
    element: <WelcomeSubscriptionPage />
  },
  error404: {
    path: '*',
    element: <Status404 />
  }
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Status404 />,
    children: [
      {
        path: routerObj.signUp.path,
        element: routerObj.signUp.element
      },
      {
        path: routerObj.signIn.path,
        element: routerObj.signIn.element
      },
      {
        path: routerObj.home.path,
        element: routerObj.home.element
      },
      {
        path: routerObj.pricing.path,
        element: routerObj.pricing.element
      },
      {
        path: routerObj.taxInformationDashboard.path,
        element: routerObj.taxInformationDashboard.element
      },
      {
        path: routerObj.usersDashboard.path,
        element: routerObj.usersDashboard.element
      },
      {
        path: routerObj.profile.path,
        element: routerObj.profile.element
      },
      {
        path: routerObj.renewPassword.path,
        element: routerObj.renewPassword.element
      },
      {
        path: routerObj.recoverPassword.path,
        element: routerObj.recoverPassword.element
      },
      {
        path: routerObj.updateTaxInformation.path,
        element: routerObj.updateTaxInformation.element
      },
      {
        path: routerObj.createTaxInformation.path,
        element: routerObj.createTaxInformation.element
      },
      {
        path: routerObj.AddUser.path,
        element: routerObj.AddUser.element
      },
      {
        path: routerObj.UpdateUser.path,
        element: routerObj.UpdateUser.element
      },
      {
        path: routerObj.receiptsDashboard.path,
        element: routerObj.receiptsDashboard.element
      },
      {
        path: routerObj.receiptDetails.path,
        element: routerObj.receiptDetails.element
      },
      {
        path: routerObj.welcomeSubscriptionPage.path,
        element: routerObj.welcomeSubscriptionPage.element
      }
    ]
  }
])

const Router = () => {
  return <RouterProvider router={router} />
}

export { Router }
