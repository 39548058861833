import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button } from '@chakra-ui/react'
import { ButtonProps } from '@chakra-ui/react/dist/index'

interface IConfirmModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  message?: string
  confirmMethod: () => void
  confirmMessage?: string
  cancelMessage?: string
  confirmType?: ButtonProps['colorScheme']
  cancelType?: ButtonProps['colorScheme']
}

const ConfirmDialog = React.memo(({ isOpen, onClose, title, message, confirmMethod, confirmMessage = 'Confirmar', cancelMessage = 'Cancelar', confirmType = 'red', cancelType = 'blue' }: IConfirmModalProps) => {
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={`md`}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader textAlign='center'>{title}</ModalHeader>

        <ModalBody>{message}</ModalBody>

        <ModalFooter justifyContent='center'>
          <Button
            colorScheme={confirmType}
            mr={3}
            onClick={() => {
              confirmMethod()
              onClose()
            }}
          >
            {confirmMessage}
          </Button>
          <Button
            colorScheme={cancelType}
            mr={3}
            onClick={onClose}
          >
            {cancelMessage}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export { ConfirmDialog }
