import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../../ts/interfaces/interfaces'
import { Payment } from '../../ts/interfaces/request'

interface InitialState {
  products: Array<Product>
  payment?: Payment | null
}

const initialState: InitialState = {
  products: [],
  payment: null
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<Partial<InitialState>>) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer
