import React, { useState } from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList, Flex, useMediaQuery } from '@chakra-ui/react'
import { Table, PaginationState } from '@tanstack/react-table'
import { IoArrowDown } from 'react-icons/io5'
import { PAGINATION_CONFIG } from './Table'
import { colors } from '../../utils/colors'

interface IPaginatorProps<Data extends object> {
  table: Table<Data>
  pagination: PaginationState
  pageCount: number
}

const Paginator = <Data extends object>({ table, pagination, pageCount }: IPaginatorProps<Data>) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

  return (
    <Flex
      width='fit-content'
      alignSelf='end'
      justifyContent='end'
      my='1rem'
      gap='0.7rem'
    >
      <Button
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </Button>
      <Button
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </Button>
      {isLargeScreen
        ? Array.from(Array(pageCount)).map((_, index) => (
            <Button
              key={`${_}-${index}`}
              bg={pagination.pageIndex === index ? colors.tertiary[3] : 'initial'}
              onClick={() => {
                table.setPageIndex(index)
              }}
            >
              {index + 1}
            </Button>
          ))
        : null}
      <Button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </Button>
      <Button
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </Button>
      <Menu>
        <MenuButton
          shadow='base'
          as={Button}
          rightIcon={<IoArrowDown />}
        >
          {pagination.pageSize}
        </MenuButton>
        <MenuList
          onClick={(e) => {
            //@ts-ignore
            table.setPageSize(Number(e.target.value))
          }}
        >
          {PAGINATION_CONFIG.pages.map((page, index) => (
            <MenuItem
              value={page}
              key={`${page}-${index}`}
            >
              {page}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export { Paginator }
