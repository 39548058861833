'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { routerObj } from '../components/routing/Router'
import { Flex, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import { FaPlus, FaPencilAlt } from 'react-icons/fa'
import { Title } from '../components/fragments/Title'
import { useNavigate } from 'react-router-dom'
import { Button } from '../components/fragments/Button'
import { UserService } from '../services/User'
import { useAppSelector } from '../store/hooks'
import { DataTable } from '../components/fragments/Table'
import { User, ProductType } from '../ts/interfaces/interfaces'
import { createColumnHelper } from '@tanstack/react-table'
import { Buffer } from 'buffer'
import { ConfirmDialog } from '../components/fragments/ConfirmDialog'

interface TableData extends User {
  action?: string
}

const UsersDashboardPage = () => {
  const supassedLimitModal = useDisclosure()
  const subscription = useAppSelector((state) => state.subscription)
  const navigate = useNavigate()
  const users = useAppSelector((state) => state.organization.users)
  const columnHelper = createColumnHelper<TableData>()

  React.useEffect(() => {
    UserService().getAllUsers()
  }, [])

  const userSeatsLimit: number = React.useMemo(() => {
    const seatProduct = subscription.products.find(({ type }) => type == ProductType.seat)

    return seatProduct ? (seatProduct.limit?.max as number) : 1
  }, [subscription])

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Nombre'
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: 'Email'
      }),
      columnHelper.accessor('phone_numbers', {
        cell: (info) => info.getValue(),
        header: 'WhatsApp'
      }),
      columnHelper.accessor('action', {
        cell: (data) => {
          return (
            <IconButton
              onClick={() => {
                navigate(`${routerObj.UpdateUser.cleanParamsPath}/${Buffer.from(data.row.original.id.toString()).toString('base64')}`)
              }}
              aria-label='row menu'
              icon={<FaPencilAlt />}
            />
          )
        },
        header: 'Acciones'
      })
    ],
    [navigate, users]
  )

  const handleAddUser = React.useCallback(() => {
    const seatProduct = subscription.products.find(({ type }) => type == ProductType.seat)

    console.log({ users, seatProduct })
    if ((seatProduct && (seatProduct.limit?.max as number) <= users.length) || !seatProduct) {
      return supassedLimitModal.onOpen()
    }

    navigate(routerObj.AddUser.path)
  }, [subscription, users, supassedLimitModal])

  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row'
        }}
        mb='1rem'
        alignItems='center'
        justifyContent={{
          base: '',
          md: 'space-between'
        }}
      >
        <Title>Usuarios</Title>
        <Button
          width='fit-content'
          onClick={handleAddUser}
          gap='0.4rem'
        >
          Agregar usuario{' '}
          <Icon
            aria-label='agregar grupo'
            as={FaPlus}
          />
        </Button>
      </Flex>

      <DataTable
        data={users}
        columns={columns}
      />

      <ConfirmDialog
        isOpen={supassedLimitModal.isOpen}
        onClose={supassedLimitModal.onClose}
        title={`Tu plan actual solo permite ${userSeatsLimit > 1 ? `${userSeatsLimit} usuarios` : '1 usuario'} de WhatsApp. Si quieres contratar un usuario adicional por favor contáctanos.`}
        confirmMethod={() => {
          window.open(process.env.REACT_APP_HELP_URL, '_blank')
        }}
        cancelType='red'
        confirmType='blue'
        confirmMessage='Contactar'
        cancelMessage='Cancelar'
      />
    </PageLayout>
  )
}

export { UsersDashboardPage }
