'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Badge, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Stack, Tag, Text, useMediaQuery, Button, IconButton } from '@chakra-ui/react'
import { Title } from '../components/fragments/Title'
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from '../components/fragments/Table'
import { useAppSelector } from '../store/hooks'
import { InvoiceStatus, Receipt, ReceiptStatus } from '../ts/interfaces/invoices'
import { formatCurrencyAmount } from '../utils/ui'
import { InvoicesService } from '../services/Invoices'
import { MdDownload, MdFolderZip, MdPictureAsPdf, MdTextIncrease } from 'react-icons/md'
import moment from 'moment'
import { FaEye, FaTicketAlt } from 'react-icons/fa'
import { colors, receiptStatusColor } from '../utils/colors'
import { useNavigate } from 'react-router-dom'
import { routerObj } from '../components/routing/Router'
import { downloadFile } from '../utils/values'
import { IoEye } from 'react-icons/io5'
interface TableData extends Receipt {
  actions?: string
  tax_identification?: string
  user_name?: string
  status: ReceiptStatus
}

const receiptTableColumnHelper = createColumnHelper<TableData>()

const ReceiptsDashboardPage = () => {
  const invoices = useAppSelector((state) => state.invoices)
  const navigate = useNavigate()
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

  React.useEffect(() => {
    InvoicesService().getAllReceipts()
  }, [])

  const tableData = React.useMemo(() => {
    return invoices.receipts.reduce((acc: TableData[], item) => {
      const { business_name, first_name, surname, last_name, middle_name } = item.tax_information

      const identification = item.tax_information.tax_id.length === 13 ? `${first_name} ${surname}` : business_name

      acc.push({
        ...item.data,
        status: item.status,
        tax_identification: identification,
        user_name: item?.user?.name || item?.user?.email
      })

      return acc
    }, [])
  }, [invoices])

  const receiptColumns = React.useMemo(
    () => [
      receiptTableColumnHelper.accessor('status', {
        cell: (info) => (
          //@ts-ignore
          <Badge bg={receiptStatusColor[info.getValue()]}>
            {/* @ts-ignore */}
            {ReceiptStatus[info.getValue()]}
          </Badge>
        ),
        header: 'Estatus'
      }),
      receiptTableColumnHelper.accessor('tax_identification', {
        cell: (info) => info.getValue(),
        header: 'Razón Social'
      }),
      receiptTableColumnHelper.accessor('user_name', {
        cell: (info) => info.getValue(),
        header: 'Usuario'
      }),
      receiptTableColumnHelper.accessor('store', {
        cell: (info) => (
          <Text
            fontWeight={700}
            fontSize={{ base: '0.7rem', lg: '.9rem' }}
          >
            {info.getValue()}
          </Text>
        ),
        header: 'Comercio'
      }),
      receiptTableColumnHelper.accessor('amount', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            colorScheme='blue'
            fontWeight={700}
          >
            {formatCurrencyAmount(info.getValue())}
          </Tag>
        ),
        header: 'Monto'
      }),
      receiptTableColumnHelper.accessor('receipt_date', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            fontWeight={700}
          >
            {moment(info.getValue()).format('DD/MM/YYYY')}
          </Tag>
        ),
        header: 'Fecha de Recibo'
      }),

      ...(isLargeScreen ? [] : []),
      receiptTableColumnHelper.accessor('actions', {
        cell: (info) => (
          <Flex
            columnGap='.5rem'
            alignItems='center'
          >
            <Menu>
              <MenuButton
                fontSize='.9rem'
                bg={colors.primary[2]}
                _hover={{
                  bg: colors.tertiary[3]
                }}
                as={Button}
              >
                Descargar&nbsp;
                <Icon as={MdDownload} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  isDisabled={!info.row.original.invoice_pdf_url}
                  onClick={() => {
                    window.open(info.row.original.invoice_pdf_url, '_blank')
                    // downloadFile(info.row.original.invoice_pdf_url, `${info.row.original.store}-${info.row.original.amount}.pdf`)
                  }}
                  justifyContent='space-between'
                >
                  Descargar PDF <Icon as={MdPictureAsPdf} />
                </MenuItem>
                <MenuItem
                  isDisabled={!info.row.original.invoice_xml_url}
                  onClick={() => {
                    window.open(info.row.original.invoice_xml_url, '_blank')
                    // downloadFile(info.row.original.invoice_xml_url, `${info.row.original.store}-${info.row.original.amount}.xml`)
                  }}
                  justifyContent='space-between'
                >
                  Descargar XML
                  <Icon as={MdFolderZip} />
                </MenuItem>
              </MenuList>
            </Menu>
            <IconButton
              fontSize='.9rem'
              bg={colors.tertiary[3]}
              aria-label='details button'
              onClick={() => navigate(`${routerObj.receiptDetails.cleanParamsPath}/${info.row.original.receipt_id}`)}
              padding='0.4rem'
              as={IoEye}
              cursor='pointer'
            />
          </Flex>
        ),
        header: ''
      })
    ],
    [invoices.receipts, isLargeScreen]
  )

  return (
    <PageLayout>
      <Stack mb='1rem'>
        <Flex
          flexDir={{
            base: 'column',
            md: 'row'
          }}
          mb='1rem'
          alignItems='center'
          justifyContent={{
            base: '',
            md: 'space-between'
          }}
        >
          <Title>Recibos</Title>
        </Flex>

        <DataTable
          data={tableData}
          columns={receiptColumns}
        />
      </Stack>
    </PageLayout>
  )
}

export { ReceiptsDashboardPage }
