'use client'
import React from 'react'
import { PricingBanner } from '../components/ui/PricingBanner'
import { PageLayout } from '../components/Layout/PageLayout'

const PricingPage = () => {
  return (
    <PageLayout>
      <PricingBanner />
    </PageLayout>
  )
}

export { PricingPage }
